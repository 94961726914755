import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "saharasands",
  casinoId: 50,
  GA_TRACKING:'G-XVK8JM3MW1',

  rivalChatGroupName: "Sahara Sands",
  prettyName: "Sahara Sands",
  contactEmail: "support@saharasandscasino.net",
  docsEmail: "documents@saharasandscasino.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/brands/saharasands/promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/brands/saharasands/promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/brands/saharasands/promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/brands/saharasands/promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/brands/saharasands/promo.png"}
  ],

  //SEO
  metaDescription: "Join Sahara Sands Online casino and play real cash online casino games today! Earn free spins, get no deposit bonus and welcome rewards immediately. Enjoy secure and fast transactions with safe payouts even in crypto.",
  logoName: "sahara-sands-online-casino-logo.png",
  logoAltText: "Sahara Sands Online Casino logo, featuring a camel with a bow tie and hat, symbolizing premium online casino gaming, slots, and live dealer games."
};


